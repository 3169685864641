/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */

$primary-color: #df0629;
$bg-color: #ffffff;
$tm-brown: #a08455;
$tm-gray: gray;
$tm-green: #00838a;
$tm-success: #4bb8a9;
$tm-orange: orange;
$tm-border: #DBDBDB;

$pc-large: 1200px;
$pc: 1024px;
$tablet: 768px;
$mobile: 480px;

/* Start --- Override bootstrap variables for theme */
$primary: $primary-color;

@import '@angular/cdk/overlay-prebuilt.css';

@import 'bootstrap/scss/bootstrap';
@import 'bootstrap-icons';
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
$enable-important-utilities: false;

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
}

:root {
  --main-font: 'Zen Kaku Gothic New', system-ui, sans-serif;
  --japanese-font: 'Hiragino Kaku Gothic ProN', 'Noto Sans JP', system-ui, sans-serif;
  --chinese-font: 'Noto Sans SC', system-ui, sans-serif;
  --korean-font: 'Noto Sans KR', system-ui, sans-serif;
  --english-font: 'Inter', system-ui, sans-serif;
}

a {
  text-decoration: none !important;
  color: inherit !important;
  cursor: pointer !important;
}

/* Override Bootstrap's link styles on hover */
a:hover {
  text-decoration: none !important;
  color: inherit !important;
}

/* End --- Override bootstrap variables for theme */

body {
    font-family: var(--main-font) !important;
    font-display: swap;
}

/*Step Count*/
.steps {
    font-size: 25px;
}


/*Step Count*/
.steps {
	font-size: 25px;
    color: gray;
    margin-bottom: 10px;
    font-weight: normal;
    text-align: right;
}

/*Field names*/
.fieldlabels {
	color: $tm-gray;
	text-align: left;
}

/*Icon progressbar*/
#progressbar {
    overflow: hidden;
    color: gray;

    .active {
      color: $primary-color;
    }

    li {
      list-style-type: none;
      font-size: 15px;
      width: 33%;
      float: left;
      position: relative;
      font-weight: 400;
    }
}

#progressbar #step1:before {
    content: "1";
}

#progressbar #step2:before {
    content: "2";
}

#progressbar #step3:before {
    content: "3";
}

#progressbar #confirm:before {
    font-family: Icons;
    content: "\e4c6";
}

/*Icon ProgressBar before any progress*/
#progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 20px;
    color: #ffffff;
    background: gray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px;
}

/*ProgressBar connectors*/
#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: gray;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: -1;
}

/*Color number of the step and the connector before it*/

#progressbar li.active:before, #progressbar li.active:after {
    background: $primary-color;
}

/*Animated Progress Bar*/
.progress {
	height: 20px;
}

.progress-bar {
	background-color: $tm-green;
}

.ngx-slider-span.ngx-slider-pointer {
  &.ngx-slider-pointer-min,
  &.ngx-slider-pointer-max {
    background-color: $primary-color;
    width: 16px;
    height: 16px;
    top: -7px;

    &.ngx-slider-active::after {
      background-color: $primary-color;
    }
  }

  &::after {
    background-color: $primary-color !important;
    width: 0 !important;
  }
}

.ngx-slider-span.ngx-slider-bubble {
  &.ngx-slider-model-value,
  &.ngx-slider-model-high,
  &.ngx-slider-combined {
    top: auto;
    bottom: -40px;
    background-color: #EDEDED;
    font-size: 16px;
    font-weight: 500;
    border-radius: 16px;
    padding: 4px 8px 6px 8px;
  }

  &.ngx-slider-limit {
    &.ngx-slider-floor,
    &.ngx-slider-ceil {
      visibility: hidden !important;
    }
  }
}

.ngx-slider {
  margin: 20px 0 15px !important;
}

.cc-compliance.cc-highlight {

  a {
    text-decoration: none;

    &.cc-btn.cc-deny {
      color: black;
    }

  }

  @media screen and (max-width: $mobile) {
    & {
      display:flex !important;
      flex-direction: column-reverse !important;

      a {
        width: 80vw;
      }
    }
  }

}

.cc-window.cc-banner {
  border-top: 1px solid $tm-border;

  @media screen and (max-width: $mobile) {
    & {
      display:flex;
      flex-direction: column;
    }
  }
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

ngx-otp-input {
  .ngx-otp-input-container{
    justify-content: space-around;
  }

  .ngx-otp-input.pin-input-class {
    width: 40px;
    height: 40px;
    border: 1px solid $tm-gray;
    border-radius: 5px;
  }

  /* Larger size for medium-sized screens */
  @media (min-width: 768px) {
    .ngx-otp-input.pin-input-class {
        width: 52px;
        height: 52px;
    }
  }


}

.grecaptcha-badge {
  visibility: hidden;
}

.search-canvas {
  border-radius: 10px 0 0 0 !important;
}

/* Images slider */

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

// Dynamic slide height
// Make every slide adapt to the container
// And the container adapt to the tallest element
swiper-slide {
  height: auto !important;
}

.swiper-pagination-bullets {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 10px 0;
  margin: 0;
}

.swiper-pagination-horizontal {
  flex-direction: row;
}

.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  background-color: rgba(161, 161, 161, 0.75);
  margin: 0 5px;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  transition: 300ms background-color;
}

.swiper-pagination-bullet-active {
  width: 10px;
  height: 10px;
  background-color: #ffffff; /* Active bullet color */
}



// -- Google Map Styling fixes
// The funniest part of GoogleMap API is that you don't have control of the html structure or styling
// So the recommended way to reach target style and UX is to override gm styling and play with JQuery
.gm-style .gm-style-iw-c {
  padding: 0px !important;
  flex-direction: row-reverse !important;
}

.gm-style-iw-d {
  overflow: auto !important
}

.gm-style-iw-c {
  max-height: auto !important;
}

.gm-style-iw-d {
  overflow: auto !important;
  max-height: auto !important;
}
